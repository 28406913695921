.Container {
    margin-bottom: 1rem;
}

.Info {
    padding: 24px;
    box-shadow: 0 0.5rem 1rem 0 rgba(44,51,73,.2) !important;
    margin-bottom: 1em;
    margin-top: 1em;
}

.Form {
    padding: 0 24px 24px;
    box-shadow: 0 0.5rem 1rem 0 rgba(44,51,73,.2) !important;
    margin-bottom: 1em;
    margin-top: 1em;
}

.FormWrapper {
    margin-top: 1em;
}

.ButtonWrapper {
    text-align: right;
}

.ButtonWrapper button {
    margin-left: 1rem;
}

.Stepper {
    padding: 24px 0 !important;
}

.ConfirmationForm {
    padding-top: 24px;
}

.ConfirmationText {
    margin-bottom: 1rem !important;
    font-weight: bold !important;
}
