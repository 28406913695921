.Greeting {
    padding: 1em;
    box-shadow: 0 0.5rem 1rem 0 rgba(44,51,73,.2) !important;
    margin-bottom: 1em;
}

.ContactTable {
    text-align: left;
    float: right;
}

.ContactTable th {
    color: #666;
    width: 2em;
}

.ContactTable td, .ContactTable td a {
    color: #666;
    text-decoration: none;
}
