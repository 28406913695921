.LogoWrapper {
    flex-grow: 1;
    height: 32px;
}

.Logo {
    height: 100%;
}

.DrawerLogoWrapper {
    display: flex;
    justify-content: center;
}

.DrawerLogo {
    margin: 16px 0;
    width: 50%;
}

.Drawer {
    width: 100%;
    max-width: 250px !important;
}

.DrawerContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
