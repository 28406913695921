.Signin {
    margin-top: 32px;
}

.Content {
    padding: 1em;
}

.SubmitButton {
    margin-bottom: 1em !important;
}
