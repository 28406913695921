.OrderDetail {
    padding: 24px;
    box-shadow: 0 0.5rem 1rem 0 rgba(44,51,73,.2) !important;
    margin-bottom: 1em;
    margin-top: 1em;
}

.ButtonGroup {
    text-align: right;
}
