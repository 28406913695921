.OrderSummary {
    padding: 0 0 24px;
}

.OrderGroupName {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.FormLabel {
    display: block;
    font-weight: bold;
}

.FormValue {
    display: block;
    padding-bottom: 1rem;
    white-space: pre;
}
